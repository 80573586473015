* {
  box-sizing: border-box;
}

html {
  width: 100%;
  height: 100%;
  min-height: 100%;
  background: #0C0F1A;
  font-family: $font-family;
  font-size: 62.5%;
  color: $color-font-main;
  font-weight: $weight-regular;

  overflow: hidden;

  @include breakpoint("phone-wide") {
    font-size: 50%;
  }
}

body {
  width: 100%;
  height: 100%;
  min-height: 100%;
  margin: 0;
  font-size: 1.4rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}



a,
a:active {
  color: $color-font-link;
  text-decoration: none;
  transition: color .2s;
  &.sub {
    color: $color-font-link;
    &:hover {
      color: $color-font-link;
    }
  }
}
a:hover {
  text-decoration: none;
  color: $color-font-link;
  cursor: pointer;
}
p {
  a {
    font-weight: 600;
  }
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0 0 1rem;
  a {
    color: $color-font-link;
  }
}


p {
  margin: 0;
  font-size: $font-size-m;
  line-height: #{$font-size-m * 1.5};
  & + p {
    margin-top: 2rem;
  }
}



input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}