.g-theta-graph {

  .chart-wrap {
    position: relative;
    @include row($align: stretch);
    height: 7.6rem;
    border-top: 1px solid $color-line-2;

    @include breakpoint('phone') {
      @include column($align: flex-start);
    }

    .chart-empty {
      position: absolute;
      bottom: 10px;
      left: 10px;
      right: 10px;
      height: 2px;
        background: $color-red;
    }
  }
  .chart {
    flex: 1 1 auto;
  }
  .legend {
    @include breakpoint('phone') {
      @include row();
      width: 100%;
      border-left: 0;
      flex: 0;
    }

    flex: 0 0 16rem;
    padding: 2rem;
    border-left: 1px solid $color-line-2;
  }
  .legend-item {
    margin-bottom: 1rem;
    &.to {
      .value { color: $color-green;}
    }
    &.from {
      .value { color: $color-blue;}
    }
    &.cdn {
      .value { color: $color-pink;}
    }
    .label {
      text-transform: uppercase;
      color: $color-font-sub;
      font-size: 1.2rem;
      font-weight: $weight-semi;
    }
    .value {
      font-weight: $weight-semi;
    }
    @include breakpoint('phone') {
      text-align: center;
      width: 25%;
    }
  }
}