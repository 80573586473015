.app {
  width: 100%;
  @include column($justify: center, $align: center);

  .main-content {
    @include column($justify: center, $align: center);
    width: 100rem;
    padding-top: 5.7rem;
    padding-bottom: 9rem;

    .logo {
      height: 4.2rem;
      width: 18rem;
    }

    .slogan {
      font-weight: $weight-bold;
      font-size: 1.6rem;
      color: $color-font-sub;
      letter-spacing: 0.117rem;
      margin-top: 1.7rem;
      margin-bottom: 1.5rem;
      text-align: center;
    }

    .player-wrapper {

    }
    .powered-by-wrapper {
      @include column($justify: center, $align: center);
      margin-top: 3.55rem;

      .powered-by-image {
        height: 1.5rem;
      }

      .powered-by-logos {
        @include row($justify: center, $align: center);
        margin-top: 2.4rem;

        .tva-logo {
            height: 3.3rem;
            margin-right: 2rem;
        }

        .logos-separator {
          height: 3.3rem;
        }

        .edge-node-logo {
          height: 3.3rem;
          margin-left: 2rem;
        }
      }

    }

    .edge-node-image {
      margin-top: 7.5rem;
      height: 14.7rem;
    }

    .download-for {
      font-weight: $weight-bold;
      font-size: 1.2rem;
      color: $color-font-sub;
      letter-spacing: 0.18rem;
      text-align: center;
      text-transform: uppercase;
      cursor: default;
    }

    .download-btns {
      @include row($justify: center, $align: center);
      flex-wrap: wrap;

      .download-btn {
        @include row($justify: flex-start, $align: center);
        border-radius: 2.3rem;
        border: 1px solid #3D4463;
        height: 4.6rem;
        width: 18.9rem;
        color: $color-font-main;
        font-weight: $weight-semi;
        font-size: 1.6rem;
        position: relative;
        margin: 1.7rem 0.9rem 0;

        &:hover {
          border-color: $color-green;
        }

        .btn-icon {

          &.windows {
            content: url(../assets/images/windows_icon.png);
            margin-left: 2.67rem;
            width: 2.1rem;
            height: 2.1rem;
          }
          &.mac {
            content: url(../assets/images/apple_icon.png);
            margin-left: 1.96rem;
            width: 2rem;
            height: 2.46rem;
          }
          &.linux {
            content: url(../assets/images/linux_icon.png);
            margin-left: 2.26rem;
            width: 2.6rem;
            height: 3.1rem;
          }
        }

        .btn-label {
          width: 100%;
          text-align: center;
          margin-right: 2rem;
        }
      }


    }

    .play-illustration {
      height: 20rem;
      margin-top: 4.8rem;
    }
    .theta-icon {
      margin-top: 9.4rem;
      margin-bottom: 2.8rem;
        width: 3.5rem;
        height: 5.6rem;
    }

    .theta-desc {
      text-align: center;
      color: $color-font-sub;
      font-size: 1.4rem;
      line-height: 2rem;
      font-weight: $weight-semi;
      margin-top: 2.5rem;
      max-width: 75rem;
      margin-bottom: 3.1rem;
    }

    .learn-more {
      color: $color-green;
      font-size: 1.2rem;
      font-weight: $weight-semi;
      letter-spacing: 0.12rem;
      cursor: pointer;
      margin-top: 3.2rem;

    }

    .header1 {
      font-size: 4.2rem;
      font-weight: $weight-bold;
      text-align: center;
      white-space: pre-wrap;
      margin-bottom: 1.4rem;

      &.first {
        max-width: 55rem;
      }

      &.second {
        max-width: 83rem;
      }
    }

    .header2 {
      font-size: 2.4rem;
      font-weight: $weight-bold;
      text-align: center;
      white-space: pre-wrap;
    }

    .text-wrap {
      font-size: 1.6rem;
      font-weight: $weight-semi;
      text-align: center;
      white-space: pre-wrap;
      margin-bottom: 3.1rem;
      color: $color-font-sub;
      max-width: 65rem;
    }

    .btn {
      @include row($justify: center, $align: center);
      background: $color-green;
      height: 4.6rem;
      width: 22rem;
      border-radius: 1rem;
      font-size: 1.6rem;
      font-weight: $weight-semi;
      cursor: pointer;
      color: $color-font-main;

      &:hover {
        color: $color-font-main;
        background: $color-theta-green-transparent;
      }

    }

  }

  @include breakpoint('tablet-wide') {
    .main-content {
      width: 100%;
      padding-top: 3rem;
      padding-left: 1rem;
      padding-right: 1rem;

      .slogan {
        max-width: 28rem;
      }

      .player-wrapper {
        width: 100%;
      }

      .header1, .header2, .text-wrap, .theta-desc {
        margin-left: 2rem;
        margin-right: 2rem;
      }
    }

    .player-and-traffic {
      width: 100%;
      padding-left: 1rem;
      padding-right: 1rem;
    }

  }

  a {
    margin-top: 0;
    padding-top: 0;
    color: $color-green;

    &:hover {
      color: $color-theta-green-transparent;
    }
  }
}
