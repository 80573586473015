// Font weights
$weight-light: 300;
$weight-regular: 400;
$weight-medium: 500;
$weight-semi: 600;
$weight-bold: 700;
$weight-extra-bold: 800;

// Base Font
$font-family: 'Gilroy', -apple-system, system-ui, BlinkMacSystemFont, 'Helvetica Neue', 'Helvetica', sans-serif;
$line-height: 1.4;

$font-size-xxs: 1rem;
$font-size-xs: 1.2rem;
$font-size-s: 1.4rem;
$font-size-sm: 1.5rem;
$font-size-m: 1.6rem;
$font-size-ml: 1.8rem;
$font-size-l: 2rem;
$font-size-xl: 2.6rem;
$font-size-xxl: 3.6rem;



@font-face {
  font-family: "Gilroy";
  src: url("../../assets/fonts/Gilroy/Gilroy-Light/Gilroy-Light.eot");
  src: url("../../assets/fonts/Gilroy/Gilroy-Light/Gilroy-Light.eot?#iefix") format("embedded-opentype"),
  url("../../assets/fonts/Gilroy/Gilroy-Light/Gilroy-Light.woff2") format("woff2"),
  url("../../assets/fonts/Gilroy/Gilroy-Light/Gilroy-Light.woff") format("woff"),
  url("../../assets/fonts/Gilroy/Gilroy-Light/Gilroy-Light.ttf") format("ttf"),
  url("../../assets/fonts/Gilroy/Gilroy-Light/Gilroy-Light.svg#Gilroy-Light") format("svg");
  font-style: normal;
  font-weight: $weight-light;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../assets/fonts/Gilroy/Gilroy-Regular/Gilroy-Regular.eot");
  src: url("../../assets/fonts/Gilroy/Gilroy-Regular/Gilroy-Regular.eot?#iefix") format("embedded-opentype"),
  url("../../assets/fonts/Gilroy/Gilroy-Regular/Gilroy-Regular.woff2") format("woff2"),
  url("../../assets/fonts/Gilroy/Gilroy-Regular/Gilroy-Regular.woff") format("woff"),
  url("../../assets/fonts/Gilroy/Gilroy-Regular/Gilroy-Regular.ttf") format("ttf"),
  url("../../assets/fonts/Gilroy/Gilroy-Regular/Gilroy-Regular.svg#Gilroy-Regular") format("svg");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../assets/fonts/Gilroy/Gilroy-Medium/Gilroy-Medium.eot");
  src: url("../../assets/fonts/Gilroy/Gilroy-Medium/Gilroy-Medium.eot?#iefix") format("embedded-opentype"),
  url("../../assets/fonts/Gilroy/Gilroy-Medium/Gilroy-Medium.woff2") format("woff2"),
  url("../../assets/fonts/Gilroy/Gilroy-Medium/Gilroy-Medium.woff") format("woff"),
  url("../../assets/fonts/Gilroy/Gilroy-Medium/Gilroy-Medium.ttf") format("ttf"),
  url("../../assets/fonts/Gilroy/Gilroy-Medium/Gilroy-Medium.svg#Gilroy-Medium") format("svg");
  font-style: normal;
  font-weight: $weight-medium;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../assets/fonts/Gilroy/Gilroy-Bold/Gilroy-Bold.eot");
  src: url("../../assets/fonts/Gilroy/Gilroy-Bold/Gilroy-Bold.eot?#iefix") format("embedded-opentype"),
  url("../../assets/fonts/Gilroy/Gilroy-Bold/Gilroy-Bold.woff2") format("woff2"),
  url("../../assets/fonts/Gilroy/Gilroy-Bold/Gilroy-Bold.woff") format("woff"),
  url("../../assets/fonts/Gilroy/Gilroy-Bold/Gilroy-Bold.ttf") format("ttf"),
  url("../../assets/fonts/Gilroy/Gilroy-Bold/Gilroy-Bold.svg#Gilroy-Bold") format("svg");
  font-style: normal;
  font-weight: $weight-bold;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../assets/fonts/Gilroy/Gilroy-SemiBold/Gilroy-SemiBold.eot");
  src: url("../../assets/fonts/Gilroy/Gilroy-SemiBold/Gilroy-SemiBold.eot?#iefix") format("embedded-opentype"),
  url("../../assets/fonts/Gilroy/Gilroy-SemiBold/Gilroy-SemiBold.woff2") format("woff2"),
  url("../../assets/fonts/Gilroy/Gilroy-SemiBold/Gilroy-SemiBold.woff") format("woff"),
  url("../../assets/fonts/Gilroy/Gilroy-SemiBold/Gilroy-SemiBold.ttf") format("ttf"),
  url("../../assets/fonts/Gilroy/Gilroy-SemiBold/Gilroy-SemiBold.svg#Gilroy-SemiBold") format("svg");
  font-style: normal;
  font-weight: $weight-semi;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../assets/fonts/Gilroy/Gilroy-ExtraBold/Gilroy-ExtraBold.eot");
  src: url("../../assets/fonts/Gilroy/Gilroy-ExtraBold/Gilroy-ExtraBold.eot?#iefix") format("embedded-opentype"),
  url("../../assets/fonts/Gilroy/Gilroy-ExtraBold/Gilroy-ExtraBold.woff2") format("woff2"),
  url("../../assets/fonts/Gilroy/Gilroy-ExtraBold/Gilroy-ExtraBold.woff") format("woff"),
  url("../../assets/fonts/Gilroy/Gilroy-ExtraBold/Gilroy-ExtraBold.ttf") format("ttf"),
  url("../../assets/fonts/Gilroy/Gilroy-ExtraBold/Gilroy-ExtraBold.svg#Gilroy-ExtraBold") format("svg");
  font-style: normal;
  font-weight: $weight-extra-bold;
}
