.g-loading {
  width: 3rem;
  height: 3rem;
  flex: 0 0 3rem;
  display: block;

  $spinner-background-color: rgba($color-surface-4, .30);

  border-radius: 100%;
  border-left: 3px solid $color-green;
  border-right: 3px solid $spinner-background-color;
  border-bottom: 3px solid $spinner-background-color;
  border-top: 3px solid $spinner-background-color;

  transform: rotate(-45deg);
  opacity: .8;

  animation: loader-rotate 0.7s ease-in-out infinite;
}


@keyframes loader-rotate {
  0% {
      transform: rotate(-45deg);
  }
  100% {
      transform: rotate(+315deg);
  }
}