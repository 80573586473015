.g-player-wrapper {
  width: 100%;

  .video-player-wrapper {
    width: 1000px;
    height: 562.5px;
    position: relative;

    &.loading {
      @include column($align: center, $justify: center);
    }

    .error-wrapper {
        @include column($align: center, $justify: center);
        width: 100%;
        height: 100%;
        background: $color-black;
        border-radius: 1rem;
        padding: 2rem;

        .error-text {
            font-size: 1.6rem;
            font-weight: $weight-semi;
            color: $color-white;
            margin-bottom: 4rem;
        }

        .try-again-btn {
            @include row($align: center, $justify: center);
            width: 14rem;
            height: 4rem;
            border-radius: 0.5rem;
            background: $color-primary;
            font-size: 1.4rem;
            font-weight: $weight-semi;
            color: $color-white;
            cursor: pointer;
        }
    }

    .info-button {
      display: none;
    }

    .close-button {
      display: none;
    }

    .video-overlay-wrapper {
      @include row($align: flex-end);
      position: absolute;
      bottom: 0;
      left: 0;
      width: 1000px;
      height: 150px;
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
      //margin-bottom: 3rem;
      background: transparent linear-gradient(0deg, #0C0F1AE5 0%, #0C0F1A00 100%) 0% 0% no-repeat padding-box;

      .video-overlay {
        @include row($align: center);
        padding: 2rem;

        .video-overlay-logo {
          width: 5rem;
          height: 5rem;
          border-radius: 50%;
        }

        .video-overlay-info {
          @include column($align: flex-start, $justify: center);
          margin-left: 1.7rem;

          .overlay-title {
            font-size: 1.4rem;
            font-weight: $weight-bold;
            text-transform: uppercase;
          }
          .overlay-desc {
            font-size: 1.3rem;
            font-weight: $weight-semi;
            margin-top: 0.3rem;
            color: $color-font-sub;
            max-width: 44rem;
          }
        }
      }
    }
  }

  @include breakpoint('tablet-wide') {
    .video-player-wrapper {
      width: 100%;
      height: calc((100vw - 4rem) / 1.77);

      .info-button {
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        margin: 2rem;
        cursor: pointer;
        width: 2.4rem;
        height: 2.4rem;

        &.hidden {
          display: none;
        }
      }

      .close-button {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        margin: 2rem 1rem;
        padding: 0.24rem;
        cursor: pointer;
        width: 2.4rem;
        height: 2.4rem;

        &.hidden {
          display: none;
        }
      }

      .video-overlay-wrapper {
        @include column($align: flex-start, $justify: flex-start);
        width: 100%;
        height: 100%;
        background: #0C0F1A 0% 0% no-repeat padding-box;
        opacity: 0.85;
        top: 0;
        right: 0;

        &.hidden {
          display: none;
        }

        .video-overlay {
          @include column($align: flex-start, $justify: flex-start);
          position: relative;
          padding: 2rem 1rem;

          .video-overlay-info {
            margin-top: 2rem;
            margin-left: 0;

            .overlay-desc {
              font-size: 1.4rem;
            }
          }
        }
      }
    }
  }
}