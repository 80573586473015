.g-traffic-bar {

  @include row($align: center, $justify: center);
  width: 100%;
  margin-top: 1.7rem;
  border-radius: 1rem;
  background: #191F36;

  .traffic-graph-wrapper {
    width: 66%;

    &.short {
      width: 50%;
    }
  }

  .traffic-stats {
    @include row($align: center, $justify: center);
    width: 34%;
    padding-left: 4rem;
    padding-right: 4rem;

    &.long {
      width: 50%;
      padding-left: 2rem;
      padding-right: 2rem;

      .traffic-column {
        width: 25%;
      }
    }

    .traffic-column {
      @include column($align: flex-start, $justify: center);
      width: 50%;
      cursor: default;

      @include breakpoint('tablet-wide') {
        width: 35%;
        margin-left: 2rem;
      }

      .label {
        @include row($align: flex-end);
        font-size: 1.1rem;
        font-weight: $weight-semi;
        color: $color-font-sub;

        .info-icon {
          content: url('../../assets/images/info_button.png');
          width: 1.6rem;
          height: 1.6rem;
          margin-left: 0.4rem;
        }
      }

      .value {
        font-size: 3rem;
        font-weight: $weight-semi;
        color: $color-font-main;
        letter-spacing: 0.12rem;

        &.green {
          color: $color-green;
        }
        &.purple {
          color: $color-purple;
        }
        &.red {
            color: $color-red;
        }

        .unit {
          margin-left: 0.3rem;
          font-size: 1.5rem;
        }
      }
    }
  }

  @include breakpoint('tablet-wide') {
    @include column($align: center, $justify: center);

    .traffic-graph-wrapper {
      width: 100%;

      &.short {
        width: 100%;
      }
    }
    .traffic-stats {
      width: 100%;
      margin-top: 1.5rem;
      margin-bottom: 1rem;
      &.long {
        width: 100%;
        .traffic-column {
          width: 25%;
        }
      }
    }
  }
}