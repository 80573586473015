
/* BASE COLORS
-----------------------------*/
$color-primary: #00bFFF; // Blue
$color-primary-dark: darken($color-primary, 5%);
$color-primary-light: lighten($color-primary, 20%);

$color-secondary: #00C99A; //Green
$color-secondary-dark: darken($color-secondary, 5%);
$color-secondary-light: lighten($color-secondary, 20%);

$color-tertiary: #F53D7A; //Red
$color-tertiary-dark: darken($color-tertiary, 5%);
$color-tertiary-light: lighten($color-tertiary, 20%);


$color-black: #111111;
$color-white: #ffffff;
$color-red: #ff3056;
$color-pink: #ff309b;
$color-green: #00c99a;
$color-blue: #00bfff;
$color-purple: #9440FF;
$color-orange: #f7931e;
$color-yellow: #f5bf23;
$color-dark-purple: #b8217a;
$color-dark-green: #0d8f8b;
$color-theta: #29b3eb;

$color-tfuel: #fd852a;
$color-tfuel-orange: #fdb231;
$color-tfuel-orange-dark: #fc5024;
$color-tfuel-red: #fc5024;

$color-theta-blue: #29b3eb;
$color-theta-blue-transparent: rgba(41, 179, 235, 0.5);
$color-theta-green: #21edba;
$color-theta-green-transparent: rgba(33, 237, 186, 0.5);
$color-theta-glow: #21C4ED;

$color-edge-purple1: #7867ff;
$color-edge-purple2: #7867ff;
$color-edge-green: #1bded0;

$color-twitch: #6441a5;

$color-surface-0: $color-black;
$color-surface-1: #0F121A;
$color-surface-1: #0F121A;
$color-surface-2: #161a24;
$color-surface-3: #1D2330;
$color-surface-4: #242b3b;
$color-surface-5: #282f41;
$color-surface-6: #2A3245;
$color-surface-7: #3a465e;
$color-surface-8: #5c6780;
$color-surface-9: #768aa8;

$color-surface-red: #301515;


$color-money: $color-green;
$color-error: $color-red;
$color-success: $color-green;
$color-warning: $color-orange;
$color-notification: $color-red;


// vvvv TODO GOING AWAY! DON'T USE DIRECTLY!
$color-gray1:  #8A8FB5;
$color-gray2:  #5c6780;
$color-gray3:  #3a465e;
$color-gray4-xlight: #2A3245;
$color-gray4-light: #262B39;
$color-gray4:  #242b3b;
$color-gray5-light: #1D2330;
$color-gray5:  #161a24;
$color-gray6:  #0F121A;

$color-dark-grey: #3a465e;
$color-med-grey: #3a465e;
$color-light-grey: #768aa8;
$color-extra-light-grey: $color-light-grey;
$color-super-light-grey: $color-extra-light-grey;
// ^^^^  TODO GOING AWAY! DON'T USE DIRECTLY!





/* Page / Backgrounds
-----------------------------*/
$color-page: $color-surface-2;

$color-panel: $color-surface-4;
$color-panel-light: $color-surface-8;
$color-panel-dark: $color-surface-3;
$color-panel-dark-2: $color-surface-2;
$color-panel-on: $color-surface-5;

$color-panel-blue: #335187;
$color-panel-blue-on: #5e79bd;

$color-popup: $color-surface-5;
$color-popup-on: darken($color-surface-7, 5%);

$color-footer: $color-surface-3;

$color-sidebar: $color-surface-3;
$color-sidebar-on: $color-surface-5;

$color-sidemenu: $color-surface-6;
$color-sidemenu-on: $color-surface-7;

$color-modal: $color-surface-4;
$color-modal-light: $color-gray4-xlight;

$color-overlay: rgba(5, 6, 10, 0.92);
$color-overlay-prediction: rgba(5, 6, 10, 0.50);
$color-tutorial: #2b92e6;
$color-tooltip: rgba(5, 6, 10, 0.92);



/* LINES / BORDERS
-----------------------------*/
$color-line: $color-surface-5;

$color-line-1: $color-surface-1;
$color-line-2: $color-surface-3;
$color-line-3: $color-surface-5;
$color-line-4: $color-surface-7;

$color-line-panel: $color-surface-6;
$color-line-popup: rgba($color-surface-8, .30);
$color-line-modal: $color-surface-8;


$color-line-dark: darken($color-med-grey, 3%);
$color-line-light: rgba($color-med-grey, .90);
$color-line-extra-light: rgba($color-med-grey, .35);


/* TYPE
-----------------------------*/

$color-font-main: $color-white;
$color-font-sub: $color-gray1;
$color-font-on: $color-gray5;

$color-font-link: $color-white;
$color-font-link-hover: $color-primary;

$color-font-tfuel: $color-tfuel;
$color-font-error: $color-red;
$color-font-success: $color-green;
$color-font-warning: $color-red;

$color-font-reverse: $color-gray5;
$color-font-reverse-light: #778ba7; // light on white


$color-select-back: $color-primary;
$color-select-type: #fff;


/* BUTTON
-----------------------------*/
$color-font-button: $color-white;
$color-font-button-hover: $color-white;

$color-button: $color-primary;
$color-button-sub: $color-med-grey;
$color-button-sub-hover: lighten($color-med-grey, 15%);
$color-button-hover: lighten($color-primary, 15%);
$color-button-disabled: $color-med-grey;

$color-button-icon: $color-white;


/* FORMS
-----------------------------*/
$color-input-label: $color-primary;
$color-input-border: $color-line-extra-light;
$color-input-select: darken($color-input-label, 15%);
$color-input-text: $color-font-main;
$color-input-placeholder: $color-font-sub;
$color-input-background: $color-surface-2;
$color-input-error: $color-tertiary;
$color-input-helper: $color-font-sub;


/* XP-LEVEL
-----------------------------*/
$color-xp-gray: darken(#768AA8, 10%); //$color--gray3;//#768AA8;
$color-xp-purple: #9E72FF;
$color-xp-green: #A7D600;
$color-xp-red: #FF5960;
$color-xp-blue: #1DBFF5;
$color-xp-orange: #FFAE00;
$color-xp-pink: darken(#FB48F5, 20%);
$color-xp-yellow: darken(#FFEA2E, 5%);

$color-xp-level1: $color-xp-gray;
$color-xp-level2: $color-xp-green;
$color-xp-level3: $color-xp-blue;
$color-xp-level4: $color-xp-purple;
$color-xp-level5: $color-xp-pink;
$color-xp-level6: $color-xp-red;
$color-xp-level7: $color-xp-orange;
$color-xp-level8: $color-xp-yellow;

$color-xp-darken: 15%;
$color-xp-lighten: 15%;

/* CLIPS
------------------------------*/
$color-clips-button: #32353e;

@mixin linearVerticalGradient($top, $bottom, $start: 0%, $end: 100%){
  background: $top; /* Old browsers */
  background: -moz-linear-gradient(top,  $top $start, $bottom $end); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop($start,$top), color-stop($end,$bottom)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top,  $top $start,$bottom $end); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top,  $top $start,$bottom $end); /* Opera 11.10+ */
  background: -ms-linear-gradient(top,  $top $start,$bottom $end); /* IE10+ */
  background: linear-gradient(to bottom,  $top $start, $bottom $end); /* W3C */
}

@mixin linearVerticalGradientHalf($top, $bottom, $start: 50%, $end: 100%){
  background: $top; /* Old browsers */
  background: -moz-linear-gradient(top,  $top $start, $bottom $end); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop($start,$top), color-stop($end,$bottom)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top,  $top $start,$bottom $end); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top,  $top $start,$bottom $end); /* Opera 11.10+ */
  background: -ms-linear-gradient(top,  $top $start,$bottom $end); /* IE10+ */
  background: linear-gradient(to bottom,  $top $start, $bottom $end); /* W3C */
}


@mixin linearHorizontalGradient($left, $right) {
  background: $left; /* Old browsers */
  background: -moz-linear-gradient(left, $left 0%, $right 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, $left 0%,$right 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, $left 0%, $right 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  background-repeat: no-repeat !important;
}

@mixin lowerBlackGradient($bottom: rgba(#000, .90), $top: transparent, $height: 40%, $offset: 5%){
  @include linearVerticalGradient($top, $bottom, $start: 100% - $height, $end: 100% - $offset);
}

@mixin gradientText($left, $right, $default){
  color: $default;
  background: -webkit-linear-gradient(left, $left 0%,$right 100%); /* Only webkit compatible*/
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@mixin gradientBlueText() {
  @include gradientText($color-blue, $color-green, $color-blue);
}

@mixin blueGradient() { @include linearHorizontalGradient(#006bcf, #00bfff); }
@mixin blueGradientLight() { @include linearHorizontalGradient(#147fe3, #14d3ff); }
@mixin purpleGradient() { @include linearHorizontalGradient(#3b51a6, #5f7aed); }
@mixin purpleGradientLight() { @include linearHorizontalGradient(#4f65ba, #738eff); }
@mixin greenGradient() { @include linearHorizontalGradient(#009989, #00c99a); }
@mixin greenGradientLight() { @include linearHorizontalGradient(#14ad9d, #14ddae); }
@mixin redGradient() { @include linearHorizontalGradient(#c22442, #ff3056); }
@mixin redGradientLight() { @include linearHorizontalGradient(#d63856, #ff446a); }
@mixin redGradientVert() { @include linearVerticalGradient(#c22442, #ff3056); }

@mixin panelGradient() { @include linearVerticalGradient(#485c85, #364565); }
@mixin greyGradient() { @include linearHorizontalGradient(darken(#3a465e, 5%), #3a465e); }
@mixin blackFadeGradient() { @include linearVerticalGradient(rgba(#161a24, 75%), rgba(#161a24, 0%)); }

@mixin tfuelGradient() { @include linearHorizontalGradient($color-tfuel-red, $color-tfuel-orange); }
@mixin tfuelGradientLight() { @include linearHorizontalGradient(lighten($color-tfuel-red, 5%), lighten($color-tfuel-orange,5%)); }

@mixin thetaGradient() { @include linearHorizontalGradient($color-theta-blue, $color-theta-green); }
@mixin thetaGradientLight() { @include linearHorizontalGradient(lighten($color-theta-blue, 5%), lighten($color-theta-green,5%)); }

@mixin edgeGradient() { @include linearHorizontalGradient($color-edge-green, $color-edge-purple2); }

@mixin blueGradientVert() { @include linearVerticalGradient(#00bfff, #006bcf); }
@mixin greenGradientVert() { @include linearVerticalGradient(#009989, #00c99a); }
@mixin purpleGradientVert() { @include linearVerticalGradient(#3b51a6, #5f7aed); }

@mixin facebookGradient() { @include linearHorizontalGradient(#006bcf, #00bfff); }
@mixin steamGradient() { @include linearHorizontalGradient(#3b51a6, #5f7aed); }
@mixin twitchGradient() { @include linearHorizontalGradient(#5f3d9c, #8556db); }
