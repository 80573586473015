/**
 *
 * =======================================================================
	 Z-Index Layers
 * =======================================================================
 */

$layer-below: -1;
$layer-default: 1;
$layer-above: 10;



$layer-popup-back: 498;
$layer-popup-target: 499;
$layer-popup: 500;

$layer-overlay: 498;

$layer-sidebar: 700;
$layer-header: 800;

$layer-modal-back: 9999;
$layer-modal: 10000;
$layer-modal-above: 10010;

$layer-tooltip: 200000;

$layer-toast: 20010;